import { feedback } from "../../js/path";

export default {
  data() {
    return {
      params: "",
      filter: null,
      pageNo: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      currentPage: 1,
      fields: [
        {
          key: "id",
        },
        {
          key: "name",
        },
        {
          key: "email",
        },
        {
          key: "mobile_number",
          class: "c_touchpt_header",
        },
        {
          key: "message",
          class: "w-250",
        },
        {
          key: "is_patient",
          label: "Patient",
        },
        {
          key: "doctor_name",
          class: "c_touchpt_header",
        },
        {
          key: "mail",
        },
        {
          key: "is_query_solved",
          label: "Query Solved",
          class: "c_touchpt_header",
        },
      ],
      activeTab: "all",
      submitted: false,
      emailModal: false,
      emailform: {
        email: "",
        message: "",
        mobile_number: "",
        name: "",
      },
      profileUrl:
        process.env.VUE_APP_ENV == "development"
          ? "https://dev2.mymedisage.com/profile/"
          : process.env.VUE_APP_ENV == "staging"
          ? "https://staging.mymedisage.com/profile/"
          : process.env.VUE_APP_ENV == "production"
          ? "https://mymedisage.com/profile/"
          : "https://dev2.mymedisage.com/profile/",
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) {
        this.fetchData("search");
        this.currentPage = 1;
      } else if (this.filter.length == 0) {
        this.fetchData("search");
        this.currentPage = 1;
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData(this.activeTab);
        this.currentPage = 1;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData("search");
          this.currentPage = 1;
        }
      } else if (this.filter.length == 0) {
        this.currentPage = 1;
        this.fetchData("search");
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    openMailModal(item) {
      this.emailform.email = item.doctor_email;
      this.emailform.mobile_number = item.mobile_number;
      this.emailform.name = item.name;
      this.emailModal = true;
    },

    clearEmailModal() {
      this.submitted = false;
      this.emailform.email = "";
      this.emailform.message = "";
    },

    async sendMail() {
      try {
        this.submitted = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Please fill all required fields with valid information.",
          });
          return false;
        }
        this.$store.commit("loader/updateStatus", true);
        console.log("huih");
        const dataAppend = new FormData();
        for (var key in this.emailform) {
          dataAppend.append(key, this.emailform[key]);
        }
        const url = feedback?.sendMail;
        const res = await this.postRequest(url, dataAppend);
        if (res.status) {
          this.emailModal = false;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: res.message,
          });
        }
      } catch (err) {
        console.log(err);
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },

    async updateQueryStatus(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let index = this.tableData.data.findIndex((e) => e.id == id);
        const url = feedback?.updateQueryStatus + "/" + id;
        const data = await this.postRequest(url, {
          is_query_solved: !this.tableData.data[index].is_query_solved,
        });
        if (data.status) {
          const responseData = data.response;
          this.tableData.data[index] = responseData;
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        this.key += 1;
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = feedback?.getPatientQuery;
        if (pagination) {
          url = `${url}?filter=all&search=${this.filter ?? ""}&page=${
            this.currentPage
          }`;
        }
        this.getUrl();
        url += this.params;
        const data = await this.getRequest(url);
        if (data.status) {
          this.tableData = data?.response;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err?.data ? err?.data?.message : "Please try again!",
        });
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },
    getUrl() {
      var url = new URL(window.location);
      if (this.activeTab == null || this.activeTab == "") {
        this.activeTab = "all";
      }
      url.searchParams.has("filter")
        ? url.searchParams.set("filter", this.activeTab)
        : url.searchParams.append("filter", this.activeTab);
      if (this.filter != null && this.filter != "") {
        url.searchParams.has("search")
          ? url.searchParams.set("search", this.filter)
          : url.searchParams.append("search", this.filter);
      } else {
        url.searchParams.delete("search");
      }
      if (this.currentPage != null && this.currentPage != "") {
        url.searchParams.has("page")
          ? url.searchParams.set("page", this.currentPage)
          : url.searchParams.append("page", this.currentPage);
      } else {
        url.searchParams.set("page", 1);
      }
      url.search = url.searchParams;
      url = url.toString();
      history.pushState({}, null, url);
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          // this.params = `&page=${value}`;
          this.fetchData(this.activeTab);
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (this.$route.query.filter) {
      this.activeTab = this.$route.query.filter;
    }
    if (this.$route.query.search) {
      this.filter = this.$route.query.search;
    }
    if (this.$route.query.page && this.$route.query.page != 1) {
      this.currentPage = parseInt(this.$route.query.page);
    } else {
      this.fetchData(this.activeTab);
    }
  },
};
